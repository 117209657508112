import { StarIcon } from 'lucide-react'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import { Card, CardContent } from './ui/card'
import type { AgentReview } from '@prisma/client'

type Review = Omit<AgentReview, 'createdAt' | 'updatedAt'>
type ReviewListProps = {
  reviews: Review[]
}
export default function ReviewList({ reviews }: ReviewListProps) {
  return (
    <div className="mx-auto space-y-2">
      {reviews.map((review) => (
        <Card
          key={review.id}
          className="min-w-70 mx-auto max-h-[394px] w-full max-w-[358px] border-none bg-background text-[#333333]">
          <CardContent className="space-y-2 p-4">
            <div className="flex items-start gap-4">
              <Avatar className="h-10 w-10">
                <AvatarImage src={review.avatarUrl} alt={review.reviewer} />
                <AvatarFallback>
                  {review.reviewer
                    .split(' ')
                    .map((n) => n[0])
                    .join('')}
                </AvatarFallback>
              </Avatar>
              <div className="flex-1 space-y-2">
                <div className="space-y-1">
                  <h3 className="text-base font-semibold">{review.reviewer}</h3>
                  <div className="flex items-center gap-0.5 text-sm">
                    {Array.from({ length: review.rating }).map((_, i) => (
                      <StarIcon key={i} className="h-4 w-4 fill-[#F2D439] stroke-none" />
                    ))}
                    {Array.from({ length: 5 - review.rating }).map((_, i) => (
                      <StarIcon key={i} className="h-4 w-4 fill-[#DFE0E1] stroke-none" />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <p className="mt-2 text-left text-sm">{review.description}</p>
          </CardContent>
        </Card>
      ))}
    </div>
  )
}
